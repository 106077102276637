@loaderCircleSize: 7px;
@buttonBorderRadius: 24px;

#mainNav {
  text-align: center;
  padding-bottom: @spaceS;
  &:not(#popup-nav) {
    margin-bottom: 96px;
    padding-bottom: 0;
    @media (@mediaSkinny) {
      margin-bottom: 0;
    }
  }

  .nav-buttons {
    @media (@mediaSkinny) {
      margin-left: @spaceM * -1;
      width: calc(100% ~'+' @spaceM);
      display: flex;
      align-items: flex-end;
    }
  }

  button {
    flex-grow: 1;
    height: @spaceL * 2;
    min-width: 170px;
    background-color: var(--color-stonegrey-1000);
    border: 1px solid var(--color-stonegrey-1000);
    padding-top: 5px;
    padding-left: 10px;
    color: #fff;
    line-height: normal;
    font-size: inherit;
    margin-left: @spaceS;
    margin-right: @spaceS;
    .border-radius(@buttonBorderRadius);

    .loading-mixin();
    .upload-mixin();
    &.loading-button {
      padding-top: 13px;

      .ygicon-left,
      .ygicon-right {
        display: none;
      }

      .loader-button {
        display: block;
      }
    }

    @media (@mediaSkinny) {
      margin-left: @spaceM;
      margin-right: 0;
      min-width: 0;

      &.loading-button {
        padding-left: 3px;
        padding-right: 3px;
        padding-top: 13px;
      }
    }

    &:disabled {
      background-color: var(--color-stonegrey-500);
      border-color: var(--color-stonegrey-500);
    }

    @media (hover: hover) {
      &:hover:enabled {
        background-color: var(--color-stonegrey-1100);
        border-color: var(--color-stonegrey-1100);
      }
    }

    &:focus:not(:disabled),
    &:focus:not(:active) {
      .focus();
      background-color: var(--color-stonegrey-1100);
    }

    &:focus:active {
      .focus();
      background-color: var(--color-stonegrey-1200);
      border-color: var(--color-stonegrey-1200);
    }

    [class^='ygicon-'],
    [class*=' ygicon-'] {
      color: #fff;
      font-size: 18px;
    }
  }

  .loader-button {
    display: none;
    position: relative;
    padding-left: 5px;
    padding-right: 5px;
    margin: auto;
    width: 18px;

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    .circular {
      animation: rotate 2s linear infinite;
      height: 100%;
      transform-origin: center center;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    .path {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      animation: dash 1.5s ease-in-out infinite;
      stroke-linecap: round;
      stroke: #fff;
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }

      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
      }

      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
      }
    }
  }
}
