.widget-upload #main_form {
  // Overwrite the bottom margin for Upload widget specifically
  margin-bottom: @spaceL;
}

.question-upload,
#deleteModal {
  min-width: 0;
  .alert {
    &.alert-error {
      &.hide {
        display: none;
      }
    }
  }
  &-helper-text {
    color: #4a5878;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;

    @media (min-width: 768px) {
      margin-bottom: @spaceSM;
    }

    > div {
      display: flex;
      flex-direction: column;

      @media (min-width: 768px) {
        flex-direction: row;

        .align-right {
          margin-left: auto;
        }
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  &-reminder {
    color: #4a5878;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-top: 40px;
    margin-bottom: @spaceSM;

    @media (min-width: 768px) {
      margin-top: 94px;
    }
  }
  .file-list,
  .file-list-modal {
    display: none;
    border: none;
    margin-top: 0;
    padding-top: 0;
    color: #000;

    @media (min-width: 768px) {
      padding-top: 1em;
    }

    &.disabled {
      opacity: 0.5;
      text-decoration: line-through;
    }

    &-item-wrapper {
      background-color: var(--color-stonegrey-100);
      margin-bottom: @spaceS;
      border-radius: @spaceS;
    }

    &-item {
      padding: @xl-gap;
      display: flex;
      align-items: center;

      &-preview {
        width: 56px;
        height: 56px;
        background-color: var(--color-white);
        border-radius: @spaceS;
        border: 1px solid var(--color-stonegrey-500);
        margin-right: @spaceM;
        position: relative;
        overflow: hidden;

        img,
        video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        i {
          color: var(--color-stonegrey-900);
          font-size: 20px;
          display: flex;
          justify-content: center;
          margin-top: 18px;
        }
      }

      &-metadata {
        display: flex;
        flex-direction: column;
      }

      &-name {
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        color: #000;
        margin-bottom: @spaceXS;
        word-break: break-all;
      }

      &-filesize {
        color: var(--color-stonegrey-1000);
        font-size: 14px;
      }

      &-error-msg {
        text-align: end;
        margin-left: auto;
        color: var(--color-grapefruit-1100);

        @media (max-width: 700px) {
          max-width: 30%;
        }

        &-mobile {
          max-width: 100%;
          text-align: start;
          display: block;

          @media (min-width: 768px) {
            display: none;
          }

          p {
            padding: 0 20px 20px 20px;
          }
        }

        p {
          margin-bottom: 0;
        }
      }

      &-actions {
        display: flex;
        .uploaded {
          font-size: 24px;
          color: var(--color-avocado-700);
        }
      }

      &-progress {
        padding: @xl-gap;
        padding-top: 0;
        display: flex;
        align-items: center;

        .progress,
        .bar {
          background: var(--color-stonegrey-300);
          border-radius: @spaceS;
          height: 10px;
          box-shadow: none;
        }
        .progress {
          width: 100%;
          margin-bottom: 0;
        }
        .bar {
          background: var(--color-blueberry-1000);
        }
        .percent {
          font-size: 16px;
          color: var(--color-stonegrey-800);
          margin-left: @spaceM;
          width: 44px;
        }
      }

      &-error {
        background: #ffeae7;

        .btn-remove,
        .btn-retry {
          color: var(--color-grapefruit-1100) !important;
        }

        .file-list-item-progress {
          display: none !important;
        }
      }
    }

    button.btn-remove,
    button.btn-retry,
    button.btn-close {
      padding: 0;
      margin: 0;
      line-height: 1;
      border: none;
      border-radius: 100%;
      background-color: transparent;
      font-size: 36px;
      color: var(--color-stonegrey-1000);

      &:hover {
        color: var(--color-stonegrey-1200);
      }
    }
    button.btn-retry {
      font-size: 14px;
      transform: rotate(-45deg);
      position: relative;
      top: -1px;
    }
  }
}

.upload-container {
  border: none;
  padding: 0;
  text-align: center;

  @media (min-width: 768px) {
    border: 1px dashed var(--color-stonegrey-900);
    border-radius: 8px;
  }

  label {
    color: #000;
    font-size: @baseFontSize;
    font-weight: 500;
    margin: 0;
    pointer-events: none;
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }

  .spacer-label {
    color: var(--color-stonegrey-800);
    font-size: @baseFontSize;
    font-weight: 500;
    text-align: center;
    margin: 0 @spaceM;
  }

  input[type='file'] {
    visibility: hidden;
    height: 1px;
    width: 1px;
  }

  .response-button {
    background-color: var(--color-blueberry-1000);
    color: var(--color-white);
    margin: 0;
    width: 100%;

    @media (min-width: 768px) {
      width: auto;
    }

    &-icon {
      width: 18px;
      height: 18px;
      margin-left: 12px;
    }

    &-disabled {
      background-color: var(--color-stonegrey-500) !important;
      pointer-events: none !important;
      border-color: var(--color-stonegrey-500) !important;
    }
  }

  div[role='button'] {
    display: inline-block;
    -webkit-box-shadow: 0 0 0 1px #fff inset;
    -moz-box-shadow: 0 0 0 1px #fff inset;
    box-shadow: 0 0 0 1px #fff inset;
    background-color: #4d90fe;
    background-image: -webkit-linear-gradient(to bottom, #4d90fe, #4787ed);
    background-image: -moz-linear-gradient(to bottom, #4d90fe, #4787ed);
    background-image: -ms-linear-gradient(to bottom, #4d90fe, #4787ed);
    background-image: -o-linear-gradient(to bottom, #4d90fe, #4787ed);
    background-image: linear-gradient(to bottom, #4d90fe, #4787ed);
    border: 1px solid #3079ed;
    color: #fff;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    cursor: default;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1.4em;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    margin: 0;
    height: auto;
    line-height: 1;
    min-width: 54px;
    outline: 0;
    padding: 0.4em 0.8em;
    z-index: 9999;

    &:hover {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      background-color: #357ae8;
      background-image: -webkit-linear-gradient(to bottom, #4d90fe, #357ae8);
      background-image: -moz-linear-gradient(to bottom, #4d90fe, #357ae8);
      background-image: -ms-linear-gradient(to bottom, #4d90fe, #357ae8);
      background-image: -o-linear-gradient(to bottom, #4d90fe, #357ae8);
      background-image: linear-gradient(to bottom, #4d90fe, #357ae8);
      border: 1px solid #2f5bb7;
      border-bottom-color: #2f5bb7;
    }
  }

  .input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0 24px 0;

    @media (min-width: 768px) {
      padding: 56px 0;
    }

    > div {
      width: 100%;

      @media (min-width: 768px) {
        width: auto;
      }
    }
  }

  &-dk {
    margin-top: 16px;
  }
}

#unsavedModal,
#deleteModal {
  border-radius: 16px;
  border: 1px solid #ccd1db;
  box-shadow: 0px 4px 32px 0px #49494940;

  .modal-body p {
    padding: 24px;
    margin: 0;
    font-size: 14px;
  }

  .modal-footer {
    padding: 0 24px 24px 24px;
  }

  button:not(.btn-close) {
    background: #fff;
    border-radius: 32px;
    border: 1px solid #005bd7;
    padding: 6px 22px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #005bd7;

    &.btn-primary {
      background: #005bd7;
      color: #fff;
    }
  }
}

#deleteModal {
  max-width: 750px;
  width: 94%; // mobile

  @media (min-width: 768px) {
    width: 100%; // desktop and tablet
  }

  .modal-header {
    padding: 24px 24px 0 24px;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 768px) {
      padding: 40px 40px 0 40px;
    }

    h4 {
      font-weight: 500;
      color: var(--color-stonegrey-1200);
      margin: 0;
    }

    .btn-close {
      background: transparent;
      border-radius: 24px;
      border: none;
      padding: 0;
      width: 30px;
      height: 30px;

      .ygicon-popup-close {
        font-size: 30px;
        background: var(--color-stonegrey-300);
        border-radius: 24px;
      }
    }
  }

  .modal-body {
    padding: 32px 0;

    .file-list-modal {
      display: block;
      padding: 0 24px;

      @media (min-width: 768px) {
        padding: 0 40px;
      }

      .file-list-item {
        .file-list-item-error-msg,
        .file-list-item-actions {
          display: none !important;
        }
      }
      .file-list-item-error-msg-mobile {
        display: none !important;
      }
    }
  }

  .modal-footer {
    display: flex;
    flex-direction: column;
    padding: 0 24px 24px 24px;

    @media (min-width: 768px) {
      display: block;
      padding: 0 40px 40px 40px;
    }

    button {
      border: 1px solid var(--color-stonegrey-1000);
      padding: 12px 32px;
      font-size: 18px;
      color: var(--color-stonegrey-1000);

      &.btn-primary {
        background: var(--color-grapefruit-700);
        border-color: var(--color-grapefruit-700);
        color: #fff;
        text-shadow: none;
        box-shadow: none;
        margin-bottom: 16px;

        @media (min-width: 768px) {
          margin-bottom: 0;
        }
      }

      &[data-dismiss='modal'] {
        float: left;
        order: 2;
      }
    }
  }
}

html[dir='rtl'] {
  .question-upload .file-list-item-preview {
    margin-right: 0;
    margin-left: 16px;
  }
}
