/* rule and placement */
.placement-dot(@color) {
  .slider-indicator {
    .slider-indicator-line,
    .slider-indicator-dot {
      background-color: @color !important;
    }
  }
  .placement-ball:before {
    content: '';
    background-color: @color !important;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: @spaceS;
    .border-radius(50%);
  }
}

.placement-teal {
  html.legacy-colors & {
    .placement-dot(var(--color-teal-1100));
  }
  html:not(.legacy-colors) & {
    .placement-dot(var(--color-avocado-900));
  }
}
.placement-yellow {
  html.legacy-colors & {
    .placement-dot(var(--color-yellow-1100));
  }
  html:not(.legacy-colors) & {
    .placement-dot(var(--color-yellow-1000));
  }
}
.placement-orange {
  html.legacy-colors & {
    .placement-dot(var(--color-orange-1100));
  }
  html:not(.legacy-colors) & {
    .placement-dot(var(--color-orange-1000));
  }
}
.placement-green {
  html.legacy-colors & {
    .placement-dot(var(--color-green-1100));
  }
  html:not(.legacy-colors) & {
    .placement-dot(var(--color-green-1100));
  }
}
.placement-plum {
  html.legacy-colors & {
    .placement-dot(var(--color-purple-1100));
  }
  html:not(.legacy-colors) & {
    .placement-dot(var(--color-plum-1100));
  }
}
.placement-purple {
  html.legacy-colors & {
    .placement-dot(var(--color-purple-1100));
  }
  html:not(.legacy-colors) & {
    .placement-dot(var(--color-plum-1100));
  }
}
.placement-blue {
  html.legacy-colors & {
    .placement-dot(var(--color-blue-1100));
  }
  html:not(.legacy-colors) & {
    .placement-dot(var(--color-blueberry-1000));
  }
}
.placement-red {
  html.legacy-colors & {
    .placement-dot(var(--color-red-1100));
  }
  html:not(.legacy-colors) & {
    .placement-dot(var(--color-grapefruit-1000));
  }
}
.placement-pink {
  html.legacy-colors & {
    .placement-dot(var(--color-pink-1000));
  }
  html:not(.legacy-colors) & {
    .placement-dot(var(--color-pink-1000));
  }
}
.placement-brown {
  html.legacy-colors & {
    .placement-dot(var(--color-brown-1100));
  }
  html:not(.legacy-colors) & {
    .placement-dot(var(--color-brown-1000));
  }
}
.placement-tan {
  html.legacy-colors & {
    .placement-dot(var(--color-tan-1000));
  }
  html:not(.legacy-colors) & {
    .placement-dot(var(--color-tan-1000));
  }
}
.placement-gray {
  html.legacy-colors & {
    .placement-dot(var(--color-stonegrey-1000));
  }
  html:not(.legacy-colors) & {
    .placement-dot(var(--color-stonegrey-1000));
  }
}
.placement-user-defined-color {
  .placement-ball:before {
    content: '';
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: @spaceS;
    .border-radius(50%);
  }
}

#placement-slider-labels {
  text-align: center;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

#placement-notsure-label {
  max-width: 100%;
  padding: @spaceS @spaceM;
}

.placement-options {
  width: 100%;
  min-height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: @spaceXXL + @spaceS;
  margin-bottom: @spaceXXL + @spaceS;
  padding-top: @spaceS;
  padding-bottom: @spaceS;
  border: 1px dashed var(--color-white);

  @media (max-width: 767px) {
    justify-content: left;
    margin-top: @spaceL;
    margin-bottom: @spaceL;

    .placement-item:not(:last-of-type) {
      margin-bottom: @spaceS;
      width: 100%;
    }
  }
}

.placement-indicator {
  display: inline;
  text-align: center;
  height: 40px;
  margin: 14px 0 0 0;
  overflow: visible;
  position: absolute;
}

.placement-item {
  cursor: pointer;
  display: inline-table;
  margin-top: @spaceS;
  margin-bottom: @spaceS;

  @media (max-width: 767px) {
    width: 100%;
    margin-right: @spaceM;
    margin-left: @spaceM;
  }

  &:not(:last-of-type) {
    margin-right: @spaceM;
  }

  .placement-label > em {
    display: none;
  }
}

.placement-indicator {
  cursor: pointer;
}

.placement-table {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 433px;
}

.rule-right-message {
  text-align: right;
  vertical-align: middle;
  width: 100%;
  padding: 8px;
}

.placement-widget .placement-table .placement-inner,
.placement-widget .rule-table .rule-inner {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 20px;

  &.slider-input-visible {
    padding-bottom: 75px;
    @media (max-width: 640px) {
      padding-bottom: 0;
    }
  }
}
.placement-widget .placement-left-message,
.placement-widget .placement-right-message {
  float: left;
  max-width: 120px !important;
  width: 100% !important;
  display: inline;
  text-align: left !important;
  padding: 4px 0 !important;
}
.placement-widget .placement-right-message {
  float: right;
  text-align: right !important;
}
.placement-widget .rule-main-cell {
  padding: 0;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
}

.placement-item {
  .slider-indicator {
    display: none;
  }
}

.placement-pointer-text {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: @spaceL * 2;
  white-space: nowrap;
  font-size: @baseFontSize;
  font-weight: 500;
  padding: 0 22px;
  background-color: #fff;
  border: 1px solid var(--color-stonegrey-900);
  .border-radius(2px);

  .placement-ball {
    display: flex;
  }

  .placement-label img {
    max-width: inherit;
  }
}

.placement-drop {
  position: absolute;
  width: 100%;
  height: 100%;
}

.placement-slider {
  width: 100%;
  height: 100%;
  position: absolute;
  padding-bottom: @spaceXXL;

  @media (max-width: 640px) {
    padding-bottom: @spaceXXL / 2;
  }
}

.placement-widget .placement-notsure-wrapper {
  position: relative;
  border: 1px solid var(--color-stonegrey-900);
  border-style: dashed;
  height: auto;
  max-width: 400px;
  width: 100%;
  font-weight: 500;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  .placement-item {
    margin: @spaceS;
  }
}

.placement-widget #placement-notsure-label {
  text-align: left;
}
.placement-widget {
  #placement-notsure {
    border: none;
    min-height: 90px;
    height: auto;

    &:not(.placement-accepts-hover) {
      background-color: transparent;
    }

    .placement-pointer-text.dragged {
      margin-top: 0px !important;
    }
  }

  .placement-accepts-hover {
    background-color: var(--color-stonegrey-500);
  }
}

.placement-widget .placement-indicator {
  text-align: left;
  margin-top: 0px;
}
.placement-widget .placement-indicator .placement-pointer-text em {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  opacity: 0.65;
  margin-left: 4px;
}
.placement-widget .placement-options.hover-drop {
  border-color: #ccc;
}
.placement-widget {
  .slider-indicator {
    position: absolute;
  }
}

@media (min-width: 600px) {
  .placement-widget .placement-notsure-wrapper {
    margin-top: 40px;
  }
  .placement-widget .placement-left-message {
    padding-right: 5px !important;
  }
  .placement-widget .placement-right-message {
    padding-left: 5px !important;
  }
}
@media (min-width: 600px) and (max-width: 700px) {
  .placement-widget .placement-left-message,
  .placement-widget .placement-right-message {
    max-width: 70px !important;
  }
}

.placement-outer.wide_view_vertical {
  max-width: 720px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.placement-outer.wide_view_vertical .placement-wrapper {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 641px) and (max-width: 720px) {
  .placement-outer.wide_view_vertical {
    max-width: 433px;
  }
}
.placement-outer.wide_view_vertical
  .placement-options
  .placement-item:not(.ui-draggable-dragging),
.placement-outer.narrow_view_vertical
  .placement-options
  .placement-item:not(.ui-draggable-dragging) {
  left: 0px !important;
  top: 0px !important;
  width: auto;
}
.placement-outer.wide_view_vertical .placement-table {
  float: left;
  width: 180px;
}
.placement-outer.wide_view_vertical .placement-table .placement-inner {
  position: relative;
}
.placement-outer.wide_view_vertical .placement-left-message,
.placement-outer.wide_view_vertical .placement-right-message {
  text-align: center !important;
  float: none;
  max-width: 180px !important;
  display: block;
  position: absolute;
  z-index: 10;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.placement-outer.wide_view_vertical .placement-left-message {
  top: 0px;
}
.placement-outer.wide_view_vertical .placement-right-message {
  bottom: 0px;
}
.placement-outer.wide_view_vertical .placement-drop {
  height: 100%;
  width: 37px;
}
@media (max-width: 640px) {
  .placement-outer.wide_view_vertical .placement-options,
  .placement-outer.narrow_view_vertical .placement-options {
    max-width: 100%;
    margin-top: 32px !important;
  }
  .placement-outer.narrow_view_vertical .placement-drop {
    height: 100%;
    width: 37px;
  }
  .placement-outer.wide_view_vertical .placement-slider,
  .placement-outer.narrow_view_vertical .placement-slider {
    min-height: 400px;
    height: 100%;
    position: relative;
  }
  .placement-outer.narrow_view_vertical .placement-slider {
    min-height: 300px;
  }
  .rule-outer.narrow_view_horizontal .rule-table {
    width: 100%;
    margin-top: 40px;
  }
  .rule-outer.narrow_view_horizontal.wide_view_vertical .rule-table {
    margin-top: 0;
  }
  .rule-outer.narrow_view_horizontal .rule-table .rule-main-cell .rule-ruler {
    width: 100%;
    height: 24px;
    padding-right: 0;
  }
}
.placement-outer.wide_view_vertical .placement-indicator {
  text-align: right;
  width: auto;
  height: auto;
  line-height: @xl-gap * 2;
  margin: 0;
}
/* end vertical */

.narrow_view_horizontal,
.wide_view_horizontal {
  .rule-inner {
    @media (max-width: @breakpoint-md) {
      .rule-main-cell {
        margin-left: auto;
        margin-right: auto;
        padding: 0;
        max-width: 100%;
      }
    }
  }

  .slider-indicator {
    &-line {
      .horizontal-align();
    }

    &-dot {
      bottom: -12px;
      top: auto;
      .horizontal-align();
    }
  }
}

.rule-ruler {
  width: 100%;
  height: 24px;
  border: 1px solid var(--color-stonegrey-900);
  display: flex;
  position: relative;
  cursor: grab;
  .border-radius(2px);
}

.rule-levels {
  width: 100%;
  display: flex;

  .rule-level {
    flex: 1;
    height: 50%;
    border-right: 1px solid var(--color-stonegrey-900);

    &:last-child {
      border: none;
    }
  }
}

.slider-input {
  &:focus + .slider-indicator .slider-indicator-dot {
    box-shadow: 0px 0px 0px 6px var(--color-blueberry-300);
  }
}
.slider-indicator-dot:focus {
  box-shadow: 0px 0px 0px 6px var(--color-blueberry-300);
}

.rule-main-cell {
  position: relative;
}

.rule-main-cell {
  vertical-align: middle;
  width: 100%;
  padding: 0px 10px 0px 0px;
}

.rule-drag {
  position: absolute !important;
  height: 40px;
  top: 0;
  text-align: center;
  font-weight: bold;
  margin-top: @spaceS * -1;
}

.rule-ruler {
  .slider-indicator {
    height: 100%;

    &.no-value {
      .slider-indicator-line,
      .slider-indicator-dot {
        background-color: var(--color-stonegrey-900);
      }
    }

    &-line {
      background-color: var(--color-stonegrey-1100);
      width: 3px;
      height: 100%;
      margin: auto;
      z-index: 1;
    }

    &-dot {
      background-color: var(--color-stonegrey-1100);
      margin: auto;
      .border-radius(50%);
    }
  }
}

.ui-slider-horizontal .ui-slider-handle.rule-drag {
  top: 0;
  margin-left: initial;
  width: initial;
  height: 40px;
  cursor: grab;
}

/* Vertical */
td.vertical {
  width: 50%;
  padding-top: 30px;
}
td.bar.vertical {
  float: right;
}
.vertical .rule-main-cell {
  max-width: 44px;
  width: 100%;
  padding-top: 0;
}
@media (max-width: 640px) {
  .vertical .rule-ruler {
    position: absolute;
    .transform(rotate(90deg));
  }
  div[class*='vertical'] .rule-ruler .slider-indicator-value {
    position: absolute;
    top: 37px;
    right: 2px;
    font-size: 15px;
    line-height: 1;
    .transform(rotate(-90deg));
  }
}

.rule-table {
  width: 100%;
  margin: 0 auto;
  z-index: 1;

  .slider-input {
    text-align: center;
    font-weight: 700;
    z-index: 1;
    margin-top: 58px;
    min-width: 40px;
    .horizontal-align();

    @media (max-width: @breakpoint-sm) {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

.rule-table tr > td {
  text-align: center;
}
.rule-table tr > td table {
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 641px) {
  .rule-ruler {
    position: relative;
  }

  .placement-outer.wide_view_horizontal {
    .placement-indicator {
      .placement-pointer-text-container {
        top: 22px;
        .horizontal-align();
      }

      .placement-pointer-text {
        position: sticky;

        // triangle
        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 100%;
          width: 0;
          height: 0;
        }
        @triangleSize: 7px;
        &:before {
          left: ~'calc(50% - (@{triangleSize} + 2px))';
          border: @triangleSize solid transparent;
          border-bottom-color: var(--color-stonegrey-900);
          top: -14px;
        }
        &:after {
          left: ~'calc(50% - @{triangleSize} - 1px)';
          border: @triangleSize - 1px solid transparent;
          border-bottom-color: #fff;
          top: -12px;
        }
      }
    }

    .slider-indicator {
      top: -48px;
    }
  }
}

@media (max-width: 767px) {
  .rule-table {
    width: 100%;
  }
  .rule-main-cell {
    padding: 0;
    padding-top: 10px;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
  }
}

.question-rule .dk-input {
  padding: 12px 0;
  margin-top: 24px;
}

/* vertical - wide view */
.rule-outer.wide_view_vertical {
  max-width: 433px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.rule-outer.wide_view_vertical .rule-ruler {
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 0 !important;
  min-height: 300px;
  max-height: 433px;
  height: 100%;
}

.placement-outer.narrow_view_horizontal:not(.wide_view_vertical) {
  .placement-indicator {
    .placement-pointer-text-container {
      top: 22px;
      .horizontal-align();
    }

    .placement-pointer-text {
      position: sticky;

      // triangle
      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 100%;
        width: 0;
        height: 0;
      }
      @triangleSize: 7px;
      &:before {
        left: ~'calc(50% - (@{triangleSize} + 2px))';
        border: @triangleSize solid transparent;
        border-bottom-color: var(--color-stonegrey-900);
        top: -14px;
      }
      &:after {
        left: ~'calc(50% - @{triangleSize} - 1px)';
        border: @triangleSize - 1px solid transparent;
        border-bottom-color: #fff;
        top: -12px;
      }
    }
  }

  .slider-indicator {
    top: -48px;
  }
}

.focus .placement-pointer-text {
  .focus();
}

.rule-outer,
.placement-outer {
  &.wide_view_vertical .rule-inner {
    &.slider-input-visible {
      padding-bottom: 0;
    }
    .rule-main-cell {
      padding: 0;
      max-width: 24px;
      margin-top: 16px;
      margin-bottom: 16px;
    }

    .rule-ruler,
    .rule-levels {
      min-height: 300px;
      max-height: 433px;
    }

    .rule-ruler {
      margin-left: auto !important;
      margin-right: auto !important;
      padding: 0 !important;
      height: 100%;
    }

    .placement-pointer-text-container {
      right: @spaceS;
      top: -14px;
      position: absolute;
    }

    .rule-levels {
      height: 100%;
      flex-direction: column;

      .rule-level {
        border: none;
        border-bottom: 1px solid var(--color-stonegrey-900);
        width: 50%;
        align-self: flex-end;

        &:last-child {
          border: none;
        }
      }
    }

    // rule only
    .rule-drag {
      margin-left: 0;
      margin-top: -@xl-gap - 2;
      height: @xl-gap * 2;
      .transform(rotate(90deg));

      .slider-indicator {
        height: @xl-gap * 2;
        top: -13px;
      }

      .slider-indicator-dot {
        margin: 0;
        bottom: -13px;
        top: auto;
        right: -13px;
        left: auto;
      }

      .slider-input {
        .transform(translateX(-50%) rotate(-90deg));
        height: 10px;
        top: 6px;
      }
    }

    //placement only
    .placement-indicator {
      left: -7px !important;

      .slider-indicator {
        background-color: var(--color-stonegrey-1200);
        width: @xl-gap * 2;
        height: 3px;
      }
      .slider-indicator-line {
        width: 100%;
      }
      .slider-indicator-dot {
        left: -@spaceL / 2;
        margin: 0;
        .vertical-align();
      }
      .placement-pointer-text-container {
        right: @xl-gap;
        .vertical-align();
      }
    }
  }
}
.rule-outer.wide_view_vertical .rule-inner .rule-drag .slider-indicator-dot {
  bottom: -@spaceL / 2;
  right: -13px;
}

.rule-outer.wide_view_vertical.narrow_view_vertical
  .rule-inner
  .rule-drag
  .slider-indicator-dot {
  right: -5px;
  bottom: -18px;
}

.placement-outer {
  &.wide_view_vertical .rule-inner {
    .rule-ruler,
    .rule-levels {
      min-height: 400px;
      max-height: 533px;
    }
  }
}

.wide_view_vertical .rule-table {
  width: auto;
  position: relative;
}
.wide_view_vertical .rule-ruler .slider-indicator-value {
  position: absolute;
  top: 37px;
  right: 2px;
  font-size: 15px;
  line-height: 1;
  .transform(rotate(-90deg));
}

@media (max-width: 480px) {
  #placement-slider-labels {
    font-size: 14px;
  }
}

// Rule and Placement Widget under 640 pixels with narrow_view="vertical"
@media (max-width: 640px) {
  .rule-outer.narrow_view_vertical,
  .placement-outer.narrow_view_vertical {
    max-width: 433px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .rule-table {
      width: auto;
      position: relative;
    }

    .rule-main-cell {
      padding: 0;
      max-width: 24px;
      margin-top: 40px;
      margin-bottom: 40px;
      position: relative;
    }

    .rule-ruler {
      margin-left: auto !important;
      margin-right: auto !important;
      padding: 0 !important;
      min-height: 300px;
      max-height: 433px;
      height: 100%;
    }

    .rule-right-message {
      bottom: 0;
    }

    .rule-levels {
      flex-direction: column;

      .rule-level {
        border: none;
        border-bottom: 1px solid var(--color-stonegrey-900);
        width: 50%;
        align-self: flex-end;

        &:last-child {
          border: none;
        }
      }
    }

    .rule-drag {
      margin-left: 0;
      margin-top: -@xl-gap - 2;
      height: @xl-gap * 2;
      .transform(rotate(90deg));

      .slider-indicator {
        height: @xl-gap * 2;
        top: -13px;
      }

      .slider-indicator-dot {
        margin: 0;
        bottom: -@spaceM;
        top: auto;
        right: -5px;
        left: auto;
      }

      .slider-input {
        .transform(translateX(-50%) rotate(-90deg));
      }
    }
  }
  .placement-outer.narrow_view_vertical {
    .rule-inner .rule-ruler {
      min-height: 400px;
      max-height: 533px;
    }

    .placement-indicator {
      text-align: right;
      width: auto;
      height: auto;
      line-height: @xl-gap * 2;
      margin: 0;
      left: -7px !important;

      .slider-indicator {
        background-color: var(--color-stonegrey-1200);
        width: @xl-gap * 2;
        height: 3px;
      }
      .slider-indicator-line {
        width: 100%;
      }
      .slider-indicator-dot {
        left: -@spaceL / 2;
        margin: 0;
        .vertical-align();
      }
      .placement-pointer-text-container {
        right: @xl-gap;
        .vertical-align();
      }
    }
  }
}
