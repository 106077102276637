@label-text-vpadding: 12px;
@label-text-hpadding: 12px;
@label-text-offset: 2px;

.response-label {
  position: relative;
  top: 0;
  margin: 0;
  display: flex;
  align-items: flex-start;
  padding: 12px 0;

  &.align-items-center {
    align-items: center;

    .label-text {
      display: none !important;
    }
  }
}

.label-inner {
  color: var(--color-black);
  display: block;
  width: 100%; // to prevent double line after a hyphen on Safari
  margin-right: 16px;
}

.label-text {
  display: inline-block;
  .ie7-inline-block();
  width: 100%; // to prevent double line after a hyphen on Safari
  max-width: 100%;
}
