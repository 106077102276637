.make-grid-row-X(@n, @totalPercentage) when (@n > 0) {
  .grid-row-@{n} th {
    width: (@totalPercentage / @n);
  }

  .make-grid-row-X(@n - 1, @totalPercentage);
}

.make-grid-row-X(0, @totalPercentage) {
}

@rowLabelWidth: (100% / 3);
@rowLabelWidthSplitlabels: (@rowLabelWidth / 2);

.make-grid-row-X(12, (100% - @rowLabelWidth));

.matrix-container {
  overflow-x: auto;

  // Work around obscure iOS(7?) bug where a horizontally-scrolled child in a
  // vertically-scrolled parent triggers incorrectly positioned :hover effects.
  -webkit-overflow-scrolling: touch;

  @media (@mediaSkinny) {
    overflow-x: visible;
  }
}

.matrix-layout {
  &:extend(.table all);
  &:extend(.table-hover all);
  &:extend(.table-condensed all);

  // Override bootstrap's bolding.
  th {
    font-weight: normal;
  }

  // Override bootstrap's left-alignment.
  th,
  td {
    text-align: center;
  }

  // Bootstrap doesn't distinguish between thead/tbody/tfooter cells; we do.
  thead {
    td,
    th {
      vertical-align: bottom;
    }

    td {
      width: @rowLabelWidth;
    }

    // The total column doesn't need much space; it's just totals.
    .total {
      width: 10%;
    }

    th.error {
      background-color: #f2dede;
    }
  }

  // Override bootstrap's thick border between tbodys. Without this, all but
  // the first page of a displaymax grid have a thick top border.
  tbody + tbody {
    border-top-width: 0;
  }

  tbody {
    td,
    th {
      vertical-align: middle;
    }
    tr.error > th,
    tr > td.error {
      background-color: #f2dede;
    }
  }

  // Override bootstrap's image scaling, which works inconsistently across
  // browsers in auto-layout tables.
  img {
    max-width: none;
  }

  // Nested inside .grid-layout to override bootstrap.
  .grid-item-text-left {
    text-align: left;
  }

  .grid-item-text-middle {
    text-align: center;
  }

  .grid-item-text-right {
    text-align: left;

    &.total {
      text-align: center;
    }
  }

  .accordion-group-error > th,
  .accordion-group-error > td {
    background-color: #f2dede !important;
  }
}

.matrix-layout-striped {
  &:extend(.table-striped all);
}

.matrix-layout-splitlabels {
  thead td {
    width: @rowLabelWidthSplitlabels;
  }
}
